import { lazy } from 'react';

const LazyMonitoring = lazy(() => import('pages/monitoring/Monitoring'));
const LazyEditWidget = lazy(() => import('pages/editWidget/EditWidget'));
const LazyNotFound = lazy(() => import('components/notFound/NotFound'));
const LazySettings = lazy(() => import('pages/settings/Settings'));
const LazyWidgets = lazy(() => import('pages/widgets/Widgets'));
const LazyForms = lazy(() => import('pages/forms/Forms'));
const LazyPayment = lazy(() => import('pages/payment/Payment'));
const LazyResell = lazy(() => import('pages/resell/Resell'));
const LazySignOn = lazy(() => import('pages/signOn/SignOn'));
const LazyHelp = lazy(() => import('pages/help/Help'));

const routesConfig = [
  { path: '/register', element: <LazySignOn />, requiresAuth: false, hasSidebar: false },
  { path: '/register/:id', element: <LazySignOn />, requiresAuth: false, hasSidebar: false },
  { path: '/login', element: <LazySignOn />, requiresAuth: false, hasSidebar: false },
  { path: '/forgotPassword', element: <LazySignOn />, requiresAuth: false, hasSidebar: false },
  { path: '/password/:data', element: <LazySignOn />, requiresAuth: false, hasSidebar: false },
  { path: '/invitations/:id', element: <LazySignOn />, requiresAuth: false, hasSidebar: false },
  // { path: '/', element: <LazyWidgets />, requiresAuth: true, hasSidebar: false },
  { path: '/widgets', element: <LazyWidgets />, requiresAuth: true, hasSidebar: false },
  { path: '/help', element: <LazyHelp />, requiresAuth: true, hasSidebar: false },
  { path: '/monitoring', element: <LazyMonitoring />, requiresAuth: true, hasSidebar: true },
  { path: '/resell/*', element: <LazyResell />, requiresAuth: true, hasSidebar: true },
  { path: '/widget/:id', element: <LazyEditWidget />, requiresAuth: true, hasSidebar: true },
  { path: '/payment/:id', element: <LazyPayment />, requiresAuth: true, hasSidebar: true },
  { path: '/new', element: <LazyEditWidget />, requiresAuth: true, hasSidebar: true },
  { path: '/settings/*', element: <LazySettings />, requiresAuth: true, hasSidebar: true },
  { path: '/forms', element: <LazyForms />, requiresAuth: true, hasSidebar: true },
  { path: '*', element: <LazyNotFound />, requiresAuth: false, hasSidebar: false },
];

export default routesConfig;

export const signOnManagementRoutes = ['register', 'login', 'forgotPassword', 'password', 'invitations'];
