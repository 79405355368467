import { RoutingInterface } from './RoutingInterface';

export const useRoutingStore = (): RoutingInterface => ({
  routing: {
    form: {
      active: false,
      oldActive: false,
      script: '',
      oldScript: '',
      backendId: '',
      formId: '',
      oldFormId: '',
      action: '',
      oldAction: '',
    },
    meetingSchedule: {
      active: false,
      oldActive: false,
      script: '',
      oldScript: '',
      backendId: '',
    },
    whatsApp: {
      active: true,
      oldActive: false,
      phoneId: '',
      oldPhoneId: '',
      customPhoneId: '+',
      backendId: '',
      scheduleGroup: {
        active: false,
        oldActive: false,
        id: '',
        oldId: '',
        name: '',
        type: '',
      },
      estimatedWaiting: {
        active: false,
        oldActive: false,
        id: '',
        oldId: '',
        name: '',
        maxTime: 0,
        oldMaxTime: 0,
        type: '',
      },
    },
    chat: {
      active: true,
      oldActive: false,
      id: '',
      oldId: '',
      name: '',
      type: '',
      backendId: '',
      scheduleGroup: {
        active: false,
        oldActive: false,
        id: '',
        oldId: '',
        name: '',
        type: '',
      },
      estimatedWaiting: {
        active: false,
        oldActive: false,
        id: '',
        oldId: '',
        name: '',
        maxTime: 0,
        oldMaxTime: 0,
        type: '',
      },
    },
    facebookChat: {
      active: true,
      oldActive: false,
      pageId: '',
      oldPageId: '',
      name: '',
      type: '',
      backendId: '',
      scheduleGroup: {
        active: false,
        oldActive: false,
        id: '',
        oldId: '',
        name: '',
        type: '',
      },
      estimatedWaiting: {
        active: false,
        oldActive: false,
        id: '',
        oldId: '',
        name: '',
        maxTime: 0,
        oldMaxTime: 0,
        type: '',
      },
    },
    callUs: {
      active: false,
      oldActive: false,
      name: '',
      ownerType: null,
      ownerID: null,
      type: '',
      mobilePhone: '',
      oldMobilePhone: '',
      phone: '',
      backendId: '',
      scheduleGroup: {
        active: false,
        oldActive: false,
        id: '',
        oldId: '',
        name: '',
        type: '',
      },
      estimatedWaiting: {
        active: false,
        oldActive: false,
        id: '',
        oldId: '',
        name: '',
        maxTime: 0,
        oldMaxTime: 0,
        type: '',
      },
    },
    video: {
      active: false,
      oldActive: false,
      name: '',
      ownerType: null,
      ownerID: null,
      type: '',
      mobilePhone: '',
      oldMobilePhone: '',
      phone: '',
      backendId: '',
      scheduleGroup: {
        active: false,
        oldActive: false,
        id: '',
        oldId: '',
        name: '',
        type: '',
      },
      estimatedWaiting: {
        active: false,
        oldActive: false,
        id: '',
        oldId: '',
        name: '',
        maxTime: 0,
        oldMaxTime: 0,
        type: '',
      },
    },
    immediateCallback: {
      active: true,
      oldActive: false,
      id: '',
      oldId: '',
      scriptId: '',
      oldScriptId: '',
      name: '',
      type: '',
      countryValue: '',
      oldCountryValue: '',
      backendId: '',
      scheduleGroup: {
        active: false,
        oldActive: false,
        id: '',
        oldId: '',
        name: '',
        type: '',
      },
      estimatedWaiting: {
        active: false,
        oldActive: false,
        id: '',
        oldId: '',
        name: '',
        maxTime: 0,
        oldMaxTime: 0,
        type: '',
      },
    },
    scheduleCallback: {
      active: true,
      oldActive: false,
      id: '',
      oldId: '',
      scriptId: '',
      oldScriptId: '',
      name: '',
      type: '',
      countryName: '',
      countryValue: '',
      oldCountryValue: '',
      scheduleGroupId: '',
      oldScheduleGroupId: '',
      scheduleGroupName: '',
      backendId: '',
    },
  },
});
